import React, { useEffect, useState, useCallback, useRef } from "react";
import { Booking } from "../../models/ClientLayoutModel";
import { IntegrationType } from "../../models/ModelTypes";
import { useCurrentTime } from "../../helpers/DateHelper";
import { useDataContext } from "../../DataContext";

interface SingleEventViewProps {}

const SingleEvent: React.FC<SingleEventViewProps> = () => {
  const { getData } = useDataContext();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [mediaSrc, setMediaSrc] = useState<string | null>(null);
  const [mediaType, setMediaType] = useState<string | null>(null);
  const booking: Booking | undefined = getData().bookings[currentIndex];
  const [isOverflowing, setIsOverflowing] = useState(false);

  // Persistent media cache
  const mediaCache = useRef<{ [key: string]: { src: string; type: string } }>(
    {}
  );
  const descriptionRef = useRef<HTMLDivElement>(null);
  const maxEvents =
    getData().maximumNumberOfEvents || getData().bookings.length;

  const pauseRef = useRef(false);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.code === "Space") {
        pauseRef.current = !pauseRef.current;
        console.log("Pause state: ", pauseRef.current);
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    const checkOverflow = () => {
      if (descriptionRef.current) {
        setIsOverflowing(
          descriptionRef.current.scrollHeight >
            descriptionRef.current.clientHeight
        );
      }
    };

    // Check for overflow after the component renders
    checkOverflow();

    // Optionally, check again on window resize or content change
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
  }, [booking]); // Re-run whenever booking changes

  const preloadMedia = useCallback(
    async (index: number) => {
      const bookingToLoad = getData().bookings[index];
      const mediaKey = bookingToLoad.externalImageUri;

      if (!mediaKey) {
        return null;
      }

      const relatedCalendar = getData().calendars.find(
        (calendar) => calendar.id === bookingToLoad.calendarId
      );

      if (mediaCache.current[mediaKey]) {
        return mediaCache.current[mediaKey];
      }

      if (
        relatedCalendar?.calendarIntegrationType === IntegrationType.Relesys &&
        relatedCalendar?.authTokenForExternalDataOfCalendar
      ) {
        try {
          const response = await fetch(bookingToLoad.externalImageUri, {
            headers: {
              Authorization: `Bearer ${relatedCalendar.authTokenForExternalDataOfCalendar}`,
            },
          });

          if (response.ok) {
            const contentType = response.headers.get("Content-Type");
            const blob = await response.blob();
            const objectURL = URL.createObjectURL(blob);

            let mediaInfo: { src: string; type: string } | null = null;

            if (contentType?.startsWith("image/")) {
              mediaInfo = { src: objectURL, type: "image" };
            } else if (contentType?.startsWith("video/")) {
              mediaInfo = { src: objectURL, type: "video" };
            } else {
              return null;
            }

            mediaCache.current[mediaKey] = mediaInfo;
            return mediaInfo;
          } else {
            return null;
          }
        } catch (error) {
          return null;
        }
      } else {
        return null; //NYI
      }
    },
    [getData]
  );

  useEffect(() => {
    if (booking) {
      preloadMedia(currentIndex).then((media) => {
        if (media) {
          setMediaSrc(media.src);
          setMediaType(media.type);
        }
      });
    }
  }, [booking, currentIndex, preloadMedia]);

  useEffect(() => {
    if (booking) {
      // Load the first media
      preloadMedia(currentIndex).then((media) => {
        if (media) {
          setMediaSrc(media.src);
          setMediaType(media.type);
        }
      });

      // Set the interval for subsequent events
      const interval = setInterval(
        async () => {
          if (pauseRef.current) return; // Skip updates when paused
          const nextIndex = (currentIndex + 1) % maxEvents;
          const media = await preloadMedia(nextIndex);

          if (media) {
            setMediaSrc(media.src);
            setMediaType(media.type);
            setCurrentIndex(nextIndex);
          } else {
            // No media, proceed to the next event without media
            setMediaSrc(null);
            setMediaType(null);
            setCurrentIndex(nextIndex);
          }
        },
        getData().infoStyle.eventDisplayDurationInSeconds
          ? getData().infoStyle.eventDisplayDurationInSeconds * 1000
          : 30000
      ); // 30 seconds default interval

      return () => clearInterval(interval);
    }
  }, [booking, currentIndex, preloadMedia, maxEvents, getData]);

  const currentTime = useCurrentTime();

  return (
    <div
      className="app"
      style={{
        ...JSON.parse(getData().infoStyle.backgroundCss ?? "{}"),
        backgroundImage: getData()?.infoStyle?.backgroundImageBase64
          ? `url(${getData().infoStyle?.backgroundImageBase64})`
          : "{}",
      }}
    >
      {
        <div
          className="clock"
          style={JSON.parse(getData()?.infoStyle?.clockCss ?? "{}")}
        >
          {currentTime.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          })}
        </div>
      }
      <div className="event-details">
        {mediaSrc ? (
          mediaType === "image" ? (
            <img
              src={mediaSrc}
              alt={booking.title}
              style={
                getData().infoStyle.eventMediaCss
                  ? JSON.parse(getData().infoStyle.eventMediaCss)
                  : {}
              }
              className="relative"
            />
          ) : (
            <video
              src={mediaSrc}
              autoPlay
              muted
              style={
                getData().infoStyle.eventMediaCss
                  ? JSON.parse(getData().infoStyle.eventMediaCss)
                  : {}
              }
              className="relative"
            />
          )
        ) : (
          <div
            className="empty-media relative"
            style={
              getData().infoStyle.eventMediaCss
                ? JSON.parse(getData().infoStyle.eventMediaCss)
                : {}
            }
          ></div>
        )}
        <div
          style={
            getData().infoStyle.bookingTitleCss
              ? JSON.parse(getData().infoStyle.bookingTitleCss)
              : {}
          }
          className="relative"
        >
          {booking?.title}
        </div>

        <div
          ref={descriptionRef}
          className={`description relative ${isOverflowing ? "overflow" : ""}`}
          style={
            getData().infoStyle.eventDescriptionCss
              ? JSON.parse(getData().infoStyle.eventDescriptionCss)
              : {}
          }
          dangerouslySetInnerHTML={{ __html: booking?.description }}
        />

        {descriptionRef.current &&
          descriptionRef.current.scrollHeight >
            descriptionRef.current.clientHeight &&
          getData().infoStyle.overflowEllipsisPostfixString && (
            <div className="ellipsis-text-container">
              [{" "}
              <div className="ellipsis-text-wrapper">
                ... {getData().infoStyle.overflowEllipsisPostfixString || ""}
              </div>{" "}
              ]
            </div>
          )}

        {getData().bookings.length > 1 && (
          <div className="pagination-dots">
            {getData()
              .bookings.slice(0, maxEvents)
              .map((_, index) => (
                <span
                  key={index}
                  className={`dot ${index === currentIndex ? "active" : ""}`}
                  style={
                    index === currentIndex
                      ? JSON.parse(
                          getData().infoStyle.eventPaginationActiveDotCss
                        )
                      : JSON.parse(getData().infoStyle.eventPaginationDotCss)
                  }
                ></span>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleEvent;
