import { getBaseApiUrl } from "../helpers/Constants";
import { getGuid } from "../helpers/Utils";
import { CheckinVisitorRequest } from "../models/ClientLayoutCheckinModel";
import { VisitorType } from "../models/ModelTypes";

let apiUrl = getBaseApiUrl() + `ClientLayout/guid/${getGuid()}`;

export const checkInVisitor = async (
  visitorType: VisitorType,
  hostClientId: number,
  visitorName: string,
  visitorPhoneNumber: string,
  visitorCompanyName: string
) => {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), 5000); // 5 seconds timeout on request
  const checkInUrl = apiUrl + "/checkinVisitor";

  const requestBody: CheckinVisitorRequest = {
    visitorCompanyName,
    visitorName,
    visitorPhoneNumber,
    visitorType,
    hostClientId: hostClientId ?? null,
  };

  try {
    const response = await fetch(checkInUrl, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "Content-Type": "application/json", // Explicitly set the content type
      },
      body: JSON.stringify(requestBody),
    });
    clearTimeout(timeoutId);

    if (!response.ok)
      throw new Error(
        `Could not connect to server - Status: ${response.status}`
      );
  } catch (error) {
    clearTimeout(timeoutId);
    console.error(error);
    throw error;
  }
};
