// BookingDaysRows.tsx
import React from "react";
import { Calendar, Booking } from "../../models/ClientLayoutModel";
import { isBookingOnDate, useCurrentTime } from "../../helpers/DateHelper";
import { getBookingsForDateSorted, getFieldValue } from "../../helpers/Utils";
import { useDataContext } from "../../DataContext";

interface RowsWithTablesProps {
  scrollRef: React.RefObject<HTMLDivElement>;
}

const RowsWithTables: React.FC<RowsWithTablesProps> = ({ scrollRef }) => {
  const { getData } = useDataContext();
  let dataIndex = -1;
  // Utility function to generate date range
  const getDateRange = (
    dateRangeBefore: number,
    dateRangeAfter: number
  ): string[] => {
    const today = new Date();
    const startDate = new Date(today);
    startDate.setDate(today.getDate() - dateRangeBefore);
    const endDate = new Date(today);
    endDate.setDate(today.getDate() + dateRangeAfter);

    const dates: string[] = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      dates.push(currentDate.toISOString().split("T")[0]); // Format YYYY-MM-DD
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };

  // Function to render calendar header based on rowHeaderDatasetType
  const renderHeader = (date: string) => {
    const dayOfWeekCapitalized =
      new Date(date)
        .toLocaleDateString("da-DK", { weekday: "long" })
        .charAt(0)
        .toUpperCase() +
      new Date(date).toLocaleDateString("da-DK", { weekday: "long" }).slice(1);
    const dateFormatted =
      new Date(date).getDate() + "/" + (new Date(date).getMonth() + 1);

    return (
      <div
        className="day-header"
        style={JSON.parse(getData().infoStyle.dayHeaderCss ?? "{}")}
      >
        <div
          className="day-of-week"
          style={JSON.parse(getData().infoStyle.dayHeaderDayOfWeekCss ?? "{}")}
        >
          {dayOfWeekCapitalized}
        </div>
        <div
          className="date-formatted"
          style={JSON.parse(getData().infoStyle.dayHeaderDateCss ?? "{}")}
        >
          {dateFormatted}
        </div>
      </div>
    );
  };

  const renderTableContent = (
    bookingsForDate: Booking[],
    date: string,
    calendarFromData?: Calendar
  ) => {
    return (
      <div className="table-container">
        {/* Table Header */}
        {!getData().infoStyle.mergeBookingsIntoSingleCalendarView &&
          renderTableHeader()}
        {/* Table Content */}
        {bookingsForDate.map((booking) => {
          // Increment the global index if mergeBookingsIntoSingleCalendarView is true
          if (getData().infoStyle?.mergeBookingsIntoSingleCalendarView) {
            dataIndex++;
          }

          // Array to hold combined field values based on order
          const combinedFieldValues: string[] = [];

          // Iterate through the fields and combine values in the correct order
          getData().fieldNamesToDisplay.forEach((field) => {
            const value = getFieldValue(
              booking,
              field,
              calendarFromData ??
                getData().calendars.find((c) => c.id === booking.calendarId) ??
                ({} as Calendar),
              date
            );

            // Ensure the array has enough space to accommodate the 'order'
            while (combinedFieldValues.length <= field.order) {
              combinedFieldValues.push("");
            }

            // If there is already a value at this order index, append the new value
            if (combinedFieldValues[field.order]) {
              combinedFieldValues[field.order] += `<br /> ${value}`;
            } else {
              combinedFieldValues[field.order] = value;
            }
          });

          return (
            <div
              key={booking.id}
              className="table-row"
              style={{
                ...((
                  getData().infoStyle?.mergeBookingsIntoSingleCalendarView
                    ? dataIndex % 2 === 0 // Use global index if merging into a single view
                    : (bookingsForDate.indexOf(booking) + 1) % 2 === 0
                ) // Otherwise, use local index from map
                  ? JSON.parse(getData().infoStyle?.oddsCss ?? "{}")
                  : JSON.parse(getData().infoStyle?.evensCss ?? "{}")),
              }}
            >
              {combinedFieldValues.map((value, index) => (
                <div
                  key={index}
                  className="table-cell"
                  style={JSON.parse(
                    getData().fieldNamesToDisplay[index]?.valueFieldCss ?? "{}"
                  )}
                  dangerouslySetInnerHTML={{
                    __html: value,
                  }}
                ></div>
              ))}
            </div>
          );
        })}
      </div>
    );
  };

  const renderCalendars = (calendar: Calendar) => {
    const dateRange = getDateRange(
      getData().dateRangeBeforeToday,
      getData().dateRangeAfterToday
    );

    // Check if there are any bookings for the entire date range for the calendar
    const hasBookings = dateRange.some((date) =>
      getData().bookings.some(
        (booking) =>
          booking.calendarId === calendar.id &&
          isBookingOnDate(new Date(booking.start), new Date(booking.end), date)
      )
    );

    // If there are no bookings and we have a merged view, return null and skip rendering
    if (
      getData().infoStyle.mergeBookingsIntoSingleCalendarView &&
      !hasBookings
    ) {
      return null;
    }

    return (
      <div key={calendar.id} className="rows-with-tables-container">
        {!getData().infoStyle.mergeBookingsIntoSingleCalendarView && (
          <div
            className="rows-with-tables-header"
            style={JSON.parse(getData().infoStyle.calendarTitleCss ?? "{}")}
          >
            {calendar.name}
          </div>
        )}
        {dateRange.map((date) => {
          const bookingsForDate = getBookingsForDateSorted(date, calendar.id);

          return (
            <div key={date} className="date-container">
              {renderHeader(date)}
              {bookingsForDate.length > 0 &&
                renderTableContent(bookingsForDate, date, calendar)}
            </div>
          );
        })}
      </div>
    );
  };

  const renderBookings = () => {
    const dateRange = getDateRange(
      getData().dateRangeBeforeToday,
      getData().dateRangeAfterToday
    );

    return (
      <div className="merged-bookings-container">
        {dateRange.map((date) => {
          const bookingsForDateSorted = getBookingsForDateSorted(
            date,
            undefined
          );
          return (
            <div key={date} className="date-container">
              {renderHeader(date)}
              {bookingsForDateSorted.length > 0 ? (
                renderTableContent(bookingsForDateSorted, date)
              ) : (
                <div className="no-bookings"></div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const renderTableHeader = () => {
    const seenHeaders: Set<string> = new Set(); // To track unique combinations of order and displayName

    return (
      <div className="table-header">
        {getData().fieldNamesToDisplay.map((field) => {
          // Create a unique identifier based on 'order' and 'displayName'
          const headerKey = `${field.order}-${field.displayName}`;
          if (seenHeaders.has(headerKey)) {
            return null; // Skip rendering if this combination of order and displayName is already seen
          }
          seenHeaders.add(headerKey); // Mark this combination as seen
          return (
            <div
              key={field.sourceType + field.fieldName}
              className="table-header-cell"
              style={JSON.parse(field.headerFieldCss ?? "{}")}
            >
              {field.displayName}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div
      className="app"
      style={{
        backgroundImage: getData()?.infoStyle?.backgroundImageBase64
          ? `url(${getData().infoStyle?.backgroundImageBase64})`
          : "{}",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      {
        <div
          className="clock"
          style={JSON.parse(getData()?.infoStyle?.clockCss ?? "{}")}
        >
          {useCurrentTime().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          })}
        </div>
      }
      <div
        className={
          getData().infoStyle.autoscrollOnVerticalOverflow
            ? "scrollable-content"
            : ""
        }
        style={JSON.parse(getData().infoStyle.backgroundCss ?? "{}")}
        ref={scrollRef}
      >
        <div style={JSON.parse(getData().infoStyle.layoutNameCss ?? "{}")}>
          {getData().layoutName}
        </div>
        {getData().infoStyle.mergeBookingsIntoSingleCalendarView &&
          renderTableHeader()}
        {getData() &&
        getData().calendars.length > 0 &&
        !getData().infoStyle.mergeBookingsIntoSingleCalendarView ? (
          getData().calendars.map((calendar) => renderCalendars(calendar))
        ) : getData() &&
          getData().infoStyle.mergeBookingsIntoSingleCalendarView ? (
          renderBookings()
        ) : (
          <div>No calendars available.</div>
        )}
      </div>
    </div>
  );
};

export default RowsWithTables;
