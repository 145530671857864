import React, { createContext, useContext, useState } from 'react';
import { ClientLayout } from './models/ClientLayoutModel';
import dataStore from './helpers/DataStore';
import { Language } from './models/ModelTypes';

interface DataContextType {
    data: ClientLayout;
    language: Language;
    setData: (newData: ClientLayout) => void;
    setLanguage: (newLanguage: Language) => void;
    getData: () => ClientLayout;
    getLanguage: () => Language;
}

const DataContext = createContext<DataContextType | undefined>(undefined);

export const useDataContext = () => {
    const context = useContext(DataContext);
    if (!context) {
        throw new Error('useDataContext must be used within a DataProvider');
    }
    return context;
};

export const DataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [data, setData] = useState<ClientLayout>({} as ClientLayout);
    const [language, setLanguage] = useState<Language>(Language.Danish)

    const getData = ():ClientLayout => {
        return data;
    }

    const getLanguage = (): Language => {
        return language;
    }

    const updateData = (newData: ClientLayout) => {
        setData(newData)
        dataStore.setData(newData); // Update the global store
    };

    const updateLanguage = (newLanguage: Language) => {
        setLanguage(newLanguage);
        dataStore.setLanguage(newLanguage); // Update the global store
    };
    
    return (
        <DataContext.Provider value={{ data, setData: updateData, language, setLanguage: updateLanguage, getData, getLanguage }}>
            {children}
        </DataContext.Provider>
    );
};
