export enum ClientLayoutInfoType {
  RowsWithTiles = "RowsWithTiles",
  ColumnsAndRow = "ColumnsAndRow",
  RowsWithTables = "RowsWithTables",
  SingleEvent = "SingleEvent",
  MenuOfTheWeek = "MenuOfTheWeek",
  RoomAvailability = "RoomAvailability",
}

export enum ClientLayoutHeaderDatasetType {
  Calendar = "Calendar",
  Day = "Day",
  Booking = "Booking",
  BookingCustomFields = "BookingCustomFields",
}

export enum SourceType {
  Calendar = "Calendar",
  CustomCalendarField = "CustomCalendarField",
  Booking = "Booking",
  CustomBookingField = "CustomBookingField",
}

export enum ClientLayoutType {
  Info = "Info",
  Checkin = "Checkin",
  MeetingRoom = "MeetingRoom",
  Touch = "Touch",
  Wayfinding = "Wayfinding",
}

export enum IntegrationType {
  None = "None",
  Outlook = "Outlook",
  Dbu = "Dbu",
  Exchange = "Exchange",
  ICal = "ICal",
  Google = "Google",
  ActiveDirectory = "ActiveDirectory",
  Picasso = "Picasso",
  Globus = "Globus",
  Kmd = "Kmd",
  FileZilla = "FileZilla",
  Winkas = "Winkas",
  Yesplan = "Yesplan",
  Pronestor = "Pronestor",
  FtpExternal = "FtpExternal",
  Spectra = "Spectra",
  Relesys = "Relesys",
}

export enum Language {
  Danish = "Danish",
  English = "English",
  German = "German",
  Polish = "Polish",
}

export enum CheckinActionType {
  NavigateToStep = "NavigateToStep",
  ShowModal = "ShowModal",
  TriggerEvent = "TriggerEvent",
  CheckIn = "CheckIn",
  CheckOut = "CheckOut",
}

export enum CheckinStepType {
  EntrySelectClientGroupHorizontalImages = "Entry_select_client_group_horizontal_images",
  SelectClientPortraitCards = "Select_client_portrait_cards",
}

export enum VisitorType {
  Visitor = "Visitor",
  Courier = "Courier",
  VisitorNoAppointment = "VisitorNoAppointment",
  Service = "Service",
}
