import { useEffect, useState } from "react";
import staticDataStore from "./DataStore";

export const useCurrentTime = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer); // Cleanup on unmount
  }, []);

  return currentTime; // Only expose currentTime
};

export const getDateRange = (): string[] => {
  staticDataStore.getData();
  const today = new Date();
  const startDate = new Date(today);
  startDate.setDate(
    today.getDate() - staticDataStore.getData().dateRangeBeforeToday
  );
  const endDate = new Date(today);
  endDate.setDate(
    today.getDate() + staticDataStore.getData().dateRangeAfterToday
  );

  const dates: string[] = [];
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    dates.push(currentDate.toISOString().split("T")[0]);
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
};
/**
 * Checks if a given date is within the range of a booking.
 * @param bookingStartTime - The start time of the booking (can be a Date or a string in ISO format).
 * @param bookingEndTime - The end time of the booking (can be a Date or a string in ISO format).
 * @param date - The current date being checked (in YYYY-MM-DD format).
 */
export const isBookingOnDate = (
  bookingStartTime: Date | string,
  bookingEndTime: Date | string,
  date: string
): boolean => {
  // Convert bookingStartTime and bookingEndTime to Date objects if they are strings
  const startTime =
    typeof bookingStartTime === "string"
      ? new Date(bookingStartTime)
      : bookingStartTime;
  const endTime =
    typeof bookingEndTime === "string"
      ? new Date(bookingEndTime)
      : bookingEndTime;
  const currentDate = new Date(date);

  // Create copies of start and end times set to the day's bounds
  const bookingStartDate = new Date(startTime.getTime());
  bookingStartDate.setHours(0, 0, 0, 0); // Midnight of the start date

  const bookingEndDate = new Date(endTime.getTime());
  bookingEndDate.setHours(23, 59, 59, 999); // End of the day for end date

  // Compare the current date with the booking's start and end dates
  return currentDate >= bookingStartDate && currentDate <= bookingEndDate;
};
