import React, { useState } from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import "./KeyboardWrapper.css"
import { Language } from "./models/ModelTypes";

interface KeyboardWrapperProps {
    value: string;
    onChange: (input: string) => void;
    language: Language;
}

const KeyboardWrapper: React.FC<KeyboardWrapperProps> = ({ value, onChange: onInputChange, language }) => {
    const [layoutName, setLayoutName] = useState<"default" | "shift" | "numpad" | "upperCaseAlphabet">("upperCaseAlphabet");
    // Layouts for different languages
    // const layouts = {
    //     [Language.Danish]: {
    //         "default": [
    //             "q w e r t y u i o p å",
    //             "a s d f g h j k l æ ø",
    //             "z x c v b n m , . - {bksp}",
    //             "{shift} {space} {enter}"
    //         ],
    //         "shift": [
    //             "Q W E R T Y U I O P Å",
    //             "A S D F G H J K L Æ Ø",
    //             "Z X C V B N M ; : _ {bksp}",
    //             "{shift} {space} {enter}"
    //         ]
    //     } as KeyboardLayoutObject,
    //     [Language.English]: {
    //         "default": [
    //             "q w e r t y u i o p",
    //             "a s d f g h j k l",
    //             "z x c v b n m , . - {bksp}",
    //             "{shift} {space} {enter}"
    //         ],
    //         "shift": [
    //             "Q W E R T Y U I O P",
    //             "A S D F G H J K L",
    //             "Z X C V B N M < > ? {bksp}",
    //             "{shift} {space} {enter}"
    //         ]
    //     } as KeyboardLayoutObject,
    //     [Language.German]: {
    //         "default": [],
    //         "shift": []
    //     } as KeyboardLayoutObject,
    //     [Language.Polish]: {
    //         "default": [],
    //         "shift": []
    //     } as KeyboardLayoutObject,
    //     numpad: {
    //         "default": [
    //             "7 8 9",
    //             "4 5 6",
    //             "1 2 3",
    //             "0 . {bksp} {enter}"
    //         ],
    //         "shift": [""]
    //     } as KeyboardLayoutObject
    // };

    const display = {
        "{bksp}": "⌫",
        "{enter}": "↵",
        "{shift}": "⇧",
        "{space}": "Space"
    };

    const handleShiftToggle = () => {
        setLayoutName((prev) => (prev === "default" ? "shift" : "default"));
    };

    // console.log("value", value)
    // console.log("lang", language)
    // console.log("layoutname", layoutName)
    // console.log(layouts[language][layoutName] as any as KeyboardLayoutObject)

    const updateInput = (updatedInput: string) => {
        if (layoutName !== "numpad") {
            if (updatedInput.length === 0 && layoutName !== "upperCaseAlphabet")
                setLayoutName("upperCaseAlphabet")
            else
                setLayoutName("default")
        }
        onInputChange(updatedInput)
    }

    return (
        <div className="virtual-keyboard-container">
            <Keyboard
                preventMouseDownDefault={true}
                onChange={updateInput}
                input={value}
                layout={{
                    'default': [
                        '1 2 3 4 5 6 7 8 9 0 + ´ {bksp}',
                        'q w e r t y u i o p å ¨',
                        'a s d f g h j k l æ ø \'',
                        '{shift} z x c v b n m , . -',
                        '@ {space}'
                    ],
                    'shift': [
                        '! @ # ¤ % & / ( ) = ? ` {bksp}',
                        'Q W E R T Y U I O P Å ^',
                        'A S D F G H J K L Æ Ø *',
                        '{shift} Z X C V B N M ; : _',
                        '@ {space}'
                    ],
                    'upperCaseAlphabet': [
                        '1 2 3 4 5 6 7 8 9 0 + ´ {bksp}',
                        'Q W E R T Y U I O P Å ^',
                        'A S D F G H J K L Æ Ø *',
                        '{shift} Z X C V B N M ; : _',
                        '@ {space}'
                    ],
                    'numpad': [
                        '1 2 3',
                        '4 5 6',
                        '7 8 9',
                        '+ 0 {bksp}'
                    ]
                }}
                display={display}
                layoutName={layoutName}
                onKeyPress={(button) => {
                    if (button === "{shift}") {
                        handleShiftToggle();
                    }
                }}
            />
        </div>
    );
};

export default KeyboardWrapper;
