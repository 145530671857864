import React from "react";
import "./css/modal.css";

interface ArrivalNotificationModalProps {
  message: string;
  onClose: () => void;
}

const ArrivalNotificationModal: React.FC<ArrivalNotificationModalProps> = ({
  message,
  onClose,
}) => {
  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <div className="modal-content">
          <p>{message}</p>
          <button className="modal-ok-button" onClick={onClose}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default ArrivalNotificationModal;
