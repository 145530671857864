import React, { useState } from "react";
import { ClientGroup } from "../../models/ClientLayoutModel";
import ArrivalNotificationModal from "./ArrivalNotificationModal";
import "./css/mapviewer.css";
import { getBaseApiUrl } from "../../helpers/Constants";
import { WayfindingLocation } from "../../models/ClientLayoutWayfindingModel";
import { useDataContext } from "../../DataContext";

interface MapViewerProps {
  selectedLocation: WayfindingLocation;
  selectedClientGroup: ClientGroup;
  onBack: () => void;
}

const MapViewer: React.FC<MapViewerProps> = ({
  selectedLocation,
  selectedClientGroup,
  onBack,
}) => {
  const { getData } = useDataContext();
  const [modalMessage, setModalMessage] = useState<string | null>(null);

  const companyName = selectedClientGroup.name;

  const map = selectedLocation
    ? getData().wayfindingMaps.find(
        (m) => m.wayfindingFloorId === selectedLocation.wayfindingFloorId
      )
    : null;

  const handleArrivalNotification = async (arrivalType: number) => {
    if (!selectedLocation || !map) {
      console.error("Location or Map details are missing somehow");
      return;
    }

    try {
      const response = await fetch(
        getBaseApiUrl() +
          `client-groups/${selectedClientGroup.id}/wayfindingNotifyArrival?arrivalType=${arrivalType}&mapId=${map.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        console.error(`Failed to send ${arrivalType} notification.`);
      }

      // Set modal message based on arrival type
      const message =
        arrivalType === 1
          ? "En besked om din ankomst er sendt til " + companyName
          : "En besked om pakkens ankomst er sendt til " +
            companyName +
            ". Hav en dejlig dag!";
      setModalMessage(message); // Open modal with message
    } catch (error) {
      setModalMessage(
        "Kunne ikke sende en besked til " +
          companyName +
          ", kontakt venligst receptionen"
      );
      console.error(`Error sending ${arrivalType} notification:`, error);
    }
    setTimeout(() => {
      onBack();
    }, 10000);
  };

  const closeModal = () => {
    setModalMessage(null); // Close the modal
    onBack(); // Reset to default state
  };

  return (
    <div className="root-container">
      {modalMessage && (
        <ArrivalNotificationModal message={modalMessage} onClose={closeModal} />
      )}

      <button onClick={onBack} className="back-button">
        Tilbage til forside
      </button>

      <div className="left-side-root-container">
        <div className="left-side-company-and-location-container">
          <div className="left-side-company-name">
            {companyName || "Unknown"}
          </div>
          <div className="left-side-location-text">
            {selectedLocation?.title}
          </div>
        </div>
        <div className="left-side-line" />
        <div className="left-side-you-are-here-container">
          <div className="left-side-you-are-here-text">Her står du</div>
          <div className="location-pin start example" />
        </div>
        <div className="left-side-buttons-container">
          <button
            className="left-side-button"
            onClick={() => handleArrivalNotification(1)}
          >
            MØDEANKOMST
          </button>
          <button
            className="left-side-button"
            onClick={() => handleArrivalNotification(0)}
          >
            FRAGT LEVERING
          </button>
        </div>
      </div>

      <div className="right-side-container">
        {map ? (
          <div className="right-side-map-container">
            <img
              src={map.mapImageBase64}
              alt="Location Map"
              className="right-side-map-image"
            />
            {selectedLocation && (
              <div
                className="location-pin target"
                style={{
                  top: `${selectedLocation.coordinateY}%`,
                  left: `${selectedLocation.coordinateX}%`,
                }}
              ></div>
            )}
            <div
              className="location-pin start"
              style={{
                top: `${map.youAreHereYCoordinate}%`,
                left: `${map.youAreHereXCoordinate}%`,
              }}
            ></div>
          </div>
        ) : (
          <p>Map not available</p>
        )}
      </div>
    </div>
  );
};

export default MapViewer;
