import { useState } from "react";
import { getStepStyle, getTranslation } from "../../../helpers/Utils";
import { Client, ClientGroup } from "../../../models/ClientLayoutModel";
import "./SelectClientPortraitCards.css";
import KeyboardWrapper from "../../../KeyboardWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faHouse } from "@fortawesome/free-solid-svg-icons";
import silhoutte from "../../../images/silhouette.jpg";
import { useDataContext } from "../../../DataContext";
import { VisitorType } from "../../../models/ModelTypes";

interface Props {
  selectedClientGroup: ClientGroup;
  resetCheckin: (onlyResetModal?: boolean) => void;
  handleAction: (key: string, visitorType: VisitorType, client: Client) => void;
  setClient: (client: Client) => void;
  setVisitorType: (type: VisitorType) => void;
}

const SelectClientPortraitCards: React.FC<Props> = ({
  selectedClientGroup,
  resetCheckin,
  handleAction,
  setClient,
  setVisitorType,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const { getData } = useDataContext();
  const allClients = selectedClientGroup
    ? getData().clients.filter(
        (c) => c.clientGroupId === selectedClientGroup.id
      )
    : getData().clients;

  const filteredClients = allClients.filter((client) =>
    client.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div
      className="Select-client-portrait-cards-outer-container"
      style={{
        backgroundImage: selectedClientGroup.backgroundImageBase64
          ? `url(${selectedClientGroup.backgroundImageBase64})`
          : "{}",
      }}
    >
      <div className="Select-client-portrait-cards-inner-container">
        <div className="Select-client-portrait-cards-header">
          <div className="Select-client-portrait-cards-logo">
            {selectedClientGroup.logoImageBase64 && (
              <img src={selectedClientGroup.logoImageBase64} alt="Logo" />
            )}
          </div>
          <div className="Select-client-portrait-cards-home-button">
            <div
              onClick={() => resetCheckin(false)}
              style={getStepStyle("home_button")}
            >
              <FontAwesomeIcon icon={faHouse} className="home-button" />
            </div>
          </div>
        </div>
        <div className="Select-client-portrait-cards-search">
          <div className="Select-client-portrait-cards-search-input">
            <FontAwesomeIcon icon={faSearch} className="search-icon" />
            <input
              type="text"
              placeholder={getTranslation("search_placeholder")}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
              style={getStepStyle("search_input")}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
          </div>
          <div className="Select-client-portrait-cards-search-alphabet"></div>
        </div>
        <div className="Select-client-portrait-cards-card-container">
          {filteredClients.map((client) => (
            <div
              key={client.id}
              className="Select-client-portrait-cards-card"
              onClick={() => {
                setClient(client);
                setVisitorType(VisitorType.Visitor);
                handleAction("client_selected", VisitorType.Visitor, client);
              }}
              style={getStepStyle("portrait_card")}
            >
              <div
                className="Select-client-portrait-cards-card-image"
                style={getStepStyle("card_image")}
              >
                <img
                  src={client.imageBase64 ?? silhoutte}
                  alt="Client"
                  className="client-image"
                />
              </div>
              <div
                className="Select-client-portrait-cards-card-text-container"
                style={getStepStyle("card_text_container")}
              >
                <div
                  className="Select-client-portrait-cards-card-text-line"
                  style={getStepStyle("card_text_name")}
                >
                  {client.name}
                </div>
                <div
                  className="Select-client-portrait-cards-card-text-line"
                  style={getStepStyle("card_text_title")}
                >
                  {client.jobTitle}
                </div>
                <div
                  className="Select-client-portrait-cards-card-text-line"
                  style={getStepStyle("card_text_phone")}
                >
                  {client.phoneNumber}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {isFocused && (
        <KeyboardWrapper
          value={searchTerm}
          onChange={setSearchTerm}
          language={getData()?.checkinWorkflow.defaultLanguage}
        />
      )}
    </div>
  );
};

export default SelectClientPortraitCards;
