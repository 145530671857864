import React, { useState } from "react";
import { ClientGroup } from "../../models/ClientLayoutModel";
import "./css/companyselector.css";
import { useDataContext } from "../../DataContext";

interface CompanySelectorProps {
  onCompanySelect: (clientGroup: ClientGroup, locationId: number) => void;
}

const ITEMS_PER_PAGE = 24; // Number of items to show per page
const ALPHABET = "ABCDEFGHIJKLMNOPQRSTUVWXYZÆØÅ1234567890".split("");
const ALL_KEY = "ALL";

const CompanySelector: React.FC<CompanySelectorProps> = ({
  onCompanySelect,
}) => {
  const { getData } = useDataContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedLetter, setSelectedLetter] = useState(ALL_KEY);

  // Filter companies by search term and selected letter
  const filteredCompanies = getData()
    .clientGroups.filter((company) =>
      selectedLetter !== ALL_KEY
        ? company.name.toLowerCase().startsWith(selectedLetter.toLowerCase())
        : true
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  // Pagination logic
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const paginatedCompanies = filteredCompanies.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE
  );

  const totalPages = Math.max(
    1,
    Math.ceil(filteredCompanies.length / ITEMS_PER_PAGE)
  );

  const handleCompanyClick = (company: ClientGroup) => {
    onCompanySelect(company, company.wayfindingLocationId); // Trigger the callback
  };

  const handlePageChange = (direction: "prev" | "next") => {
    if (direction === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (direction === "next" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleLetterChange = (letter: string) => {
    setSelectedLetter(letter);
    setCurrentPage(1); // Reset to the first page when changing the letter
  };

  const handleBrowseAll = () => {
    setSelectedLetter(ALL_KEY); // Reset letter filter
    setCurrentPage(1); // Start on the first page
  };

  return (
    <div className="company-selector-root-container">
      {/* Alphabetical Filter */}
      <div className="top-container">
        {ALPHABET.map((letter) => (
          <button
            key={letter}
            onClick={() => handleLetterChange(letter)}
            className={
              "alphabet-button" + (selectedLetter === letter ? " selected" : "")
            }
          >
            {letter}
          </button>
        ))}
        <br />
        <button
          onClick={handleBrowseAll}
          className={
            "all-button" + (selectedLetter === ALL_KEY ? " selected" : "")
          }
        >
          Se alle
        </button>
      </div>

      {/* Company Grid */}
      <div className="grid-container">
        {paginatedCompanies.map((company) => (
          <div
            key={company.id}
            onClick={() => handleCompanyClick(company)}
            style={JSON.parse(
              getData().wayfindingStyle.clientGroupButtonCss ?? "{}"
            )}
          >
            {company.name}
          </div>
        ))}

        {/* No Results */}
        {filteredCompanies.length === 0 && <p>Ingen firmaer fundet</p>}
      </div>

      {/* Pagination Controls */}
      <div className="pagination-controls-container">
        <button
          onClick={() => handlePageChange("prev")}
          disabled={currentPage === 1}
          className="pagination-scroll-button"
        >
          Forrige
        </button>
        <span style={{ alignSelf: "center" }}>
          Side {currentPage} ud af {totalPages}
        </span>
        <button
          onClick={() => handlePageChange("next")}
          disabled={currentPage === totalPages}
          className="pagination-scroll-button"
        >
          Næste
        </button>
      </div>
    </div>
  );
};

export default CompanySelector;
