import { Client, ClientLayout } from "../models/ClientLayoutModel";
import { Language, VisitorType } from "../models/ModelTypes";

class DataStore {
  private data: ClientLayout = {} as ClientLayout;

  private language: Language = Language.Danish;

  private client: Client = {} as Client;
  private visitorType: VisitorType = VisitorType.Visitor;

  setData(newData: ClientLayout) {
    this.data = newData;
  }

  getData(): ClientLayout {
    return this.data;
  }

  setLanguage(newLanguage: Language) {
    this.language = newLanguage;
  }

  getLanguage(): Language {
    return this.language;
  }

  setClient(client: Client) {
    this.client = client;
  }

  getClient() {
    return this.client;
  }

  setVisitorType(type: VisitorType) {
    this.visitorType = type;
  }

  getVisitorType() {
    return this.visitorType;
  }

  resetVisitorData() {
    this.client = {} as Client;
    this.visitorType = VisitorType.Visitor;
  }
}

const dataStore = new DataStore();
export default dataStore;
