import React, { useState, useEffect, useCallback, useRef } from "react";
import { ClientGroup } from "../../models/ClientLayoutModel";
import CompanySelector from "./CompanySelector";
import MapViewer from "./MapViewer";
import "./css/wayfinding.css";
import { WayfindingLocation } from "../../models/ClientLayoutWayfindingModel";
import { useDataContext } from "../../DataContext";

interface WayfindingProps {}

const Wayfinding: React.FC<WayfindingProps> = () => {
  const { getData } = useDataContext();
  const [selectedLocationId, setSelectedLocationId] =
    useState<WayfindingLocation | null>(null);
  const [selectedClientGroupId, setSelectedClientGroupId] =
    useState<ClientGroup | null>(null);
  const [resetKey, setResetKey] = useState(0); // New state to trigger reset

  const INACTIVITY_TIMEOUT = 15 * 1000; // 15 seconds
  const inactivityTimer = useRef<NodeJS.Timeout | null>(null);

  // Reset to default state
  const resetInactivityTimer = useCallback(() => {
    setSelectedLocationId(null);
    setSelectedClientGroupId(null);
    setResetKey((prev) => prev + 1); // Increment reset key to trigger re-render
  }, []);

  // Handle user interaction
  const handleUserInteraction = useCallback(() => {
    if (inactivityTimer.current) {
      clearTimeout(inactivityTimer.current); // Clear the existing timer
    }
    inactivityTimer.current = setTimeout(
      resetInactivityTimer,
      INACTIVITY_TIMEOUT
    ); // Set a new timer
  }, [resetInactivityTimer, INACTIVITY_TIMEOUT]);

  useEffect(() => {
    // Set the initial inactivity timer
    inactivityTimer.current = setTimeout(
      resetInactivityTimer,
      INACTIVITY_TIMEOUT
    );

    // Attach event listeners for user interaction
    window.addEventListener("mousemove", handleUserInteraction);
    window.addEventListener("keydown", handleUserInteraction);
    window.addEventListener("click", handleUserInteraction);

    return () => {
      // Cleanup the timer and event listeners on unmount
      if (inactivityTimer.current) {
        clearTimeout(inactivityTimer.current);
      }
      window.removeEventListener("mousemove", handleUserInteraction);
      window.removeEventListener("keydown", handleUserInteraction);
      window.removeEventListener("click", handleUserInteraction);
    };
  }, [handleUserInteraction, resetInactivityTimer, INACTIVITY_TIMEOUT]);

  const handleCompanySelect = (
    clientGroupId: ClientGroup,
    locationId: number
  ) => {
    setSelectedLocationId(
      getData().wayfindingLocations.find((l) => l.id === locationId) ?? null
    );
    setSelectedClientGroupId(clientGroupId);
  };

  const handleBackToSelector = () => {
    resetInactivityTimer(); // Reset on back
  };

  return (
    <div
      className="wayfinding-container"
      style={JSON.parse(getData().wayfindingStyle.parentContainerCss ?? "{}")}
    >
      {selectedLocationId === null || selectedClientGroupId === null ? (
        <CompanySelector
          key={resetKey} // Use resetKey to force re-render
          onCompanySelect={handleCompanySelect}
        />
      ) : (
        <MapViewer
          selectedLocation={selectedLocationId}
          selectedClientGroup={selectedClientGroupId}
          onBack={handleBackToSelector}
        />
      )}
    </div>
  );
};

export default Wayfinding;
