import { useCallback, useEffect, useRef, useState } from "react";
import { Client, ClientGroup } from "../../models/ClientLayoutModel";
import {
  CheckinActionType,
  CheckinStepType,
  VisitorType,
} from "../../models/ModelTypes";
import SelectClientGroupHorizontalWide from "./CheckinSteps/SelectClientGroupHorizontalWide";
import SelectClientPortraitCards from "./CheckinSteps/SelectClientPortraitCards";
// import dataStore from "../../helpers/DataStore";
import {
  CheckinStep,
  CheckinStepAction,
} from "../../models/ClientLayoutCheckinModel";
import LanguagePicker from "./CheckinModules/LanguagePicker";
import { useDataContext } from "../../DataContext";
import Modal from "./CheckinModules/Modal";
import { getTranslation } from "../../helpers/Utils";
import { checkInVisitor } from "../../apiRequests/CheckinRequests";
import dataStore from "../../helpers/DataStore";

const Checkin: React.FC = () => {
  const { getData, setLanguage } = useDataContext();

  const [currentCheckinStep, setCurrentCheckinStep] = useState<CheckinStep>(
    getData()?.checkinSteps.find((s) => s.order === 0) ?? ({} as CheckinStep)
  );
  const [clientGroupSelected, setClientGroupSelected] = useState<ClientGroup>(
    {} as ClientGroup
  );

  // Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [modalText, setModalText] = useState<string>("");
  const [modalConfirmActionText, setModalConfirmActionText] =
    useState<string>("");
  const [autoCloseModal, setAutocloseModal] = useState<boolean>(true);
  const [modalActionOnConfirm, setModalActionOnConfirm] = useState<() => void>(
    () => {}
  );

  // Visitor information
  //   const [stateVisitorType, setVisitorType] = useState<VisitorType>( // Not used for now
  //     VisitorType.Visitor
  //   );
  const [stateVisitorName, setVisitorName] = useState<string>("");
  const [stateVisitorPhoneNumber, setVisitorPhoneNumber] = useState<string>("");
  const [stateVisitorCompanyName, setVisitorCompanyName] = useState<string>("");
  //   const [stateHostClient, setHostClient] = useState<Client>({} as Client); // Not used for now

  const [resetKey, setResetKey] = useState(0); // New state to trigger reset

  const INACTIVITY_TIMEOUT = 15 * 1000; // 30 seconds
  const inactivityTimer = useRef<NodeJS.Timeout | null>(null);

  const onReset = useCallback(
    (onlyResetModal: boolean = false) => {
      if (onlyResetModal) {
        setModalText("");
        setModalTitle("");
        setIsModalOpen(false);
        setAutocloseModal(true);
        setModalActionOnConfirm(() => {});
        return;
      }

      setLanguage(getData().checkinWorkflow.defaultLanguage);
      setCurrentCheckinStep(
        getData().checkinSteps?.find((s) => s.order === 0) ??
          ({} as CheckinStep)
      );
      setClientGroupSelected({} as ClientGroup);
      setVisitorName("");
      setVisitorPhoneNumber("");
      setVisitorCompanyName("");
      setModalText("");
      setModalTitle("");
      setIsModalOpen(false);
      setAutocloseModal(true);
      setModalActionOnConfirm(() => {});
      //   setHostClient({} as Client);
      dataStore.resetVisitorData();
    },
    [getData, setLanguage]
  );

  // Reset to default state
  const resetInactivityTimer = useCallback(() => {
    onReset();
    setResetKey((prev) => prev + 1); // Increment reset key to trigger re-render
  }, [onReset]);

  // Handle user interaction
  const handleUserInteraction = useCallback(() => {
    if (inactivityTimer.current) {
      clearTimeout(inactivityTimer.current); // Clear the existing timer
    }
    inactivityTimer.current = setTimeout(
      resetInactivityTimer,
      INACTIVITY_TIMEOUT
    ); // Set a new timer
  }, [resetInactivityTimer, INACTIVITY_TIMEOUT]);

  useEffect(() => {
    // Set the initial inactivity timer
    inactivityTimer.current = setTimeout(
      resetInactivityTimer,
      INACTIVITY_TIMEOUT
    );

    // Attach event listeners for user interaction
    window.addEventListener("mousemove", handleUserInteraction);
    window.addEventListener("keydown", handleUserInteraction);
    window.addEventListener("click", handleUserInteraction);

    return () => {
      // Cleanup the timer and event listeners on unmount
      if (inactivityTimer.current) {
        clearTimeout(inactivityTimer.current);
      }
      window.removeEventListener("mousemove", handleUserInteraction);
      window.removeEventListener("keydown", handleUserInteraction);
      window.removeEventListener("click", handleUserInteraction);
    };
  }, [handleUserInteraction, resetInactivityTimer, INACTIVITY_TIMEOUT]);

  const onClientGroupSelected = (clientGroupSelected: ClientGroup) => {
    setClientGroupSelected(clientGroupSelected);
  };

  const onClientSelected = (clientSelected: Client) => {
    dataStore.setClient(clientSelected);
    // setHostClient(clientSelected);
  };

  const onVisitorTypeSelected = (type: VisitorType) => {
    dataStore.setVisitorType(type);
    // setVisitorType(type);
  };

  // Trigger checkIn after state updates
  const handleCheckIn = async () => {
    try {
      await checkInVisitor(
        dataStore.getVisitorType(),
        dataStore.getClient().id,
        stateVisitorName,
        stateVisitorPhoneNumber,
        stateVisitorCompanyName
      );
    } catch (error: any) {
      setAutocloseModal(false);
      setModalTitle("Could not connect to server");
      setModalText(error.toString());
      setIsModalOpen(true);
    }
  };

  const onHandleAction = async (actionKey: string) => {
    const stepActions = getData().checkinStepActions as CheckinStepAction[];
    const actions = stepActions.filter((a) => a.key === actionKey);

    actions.forEach((action) => {
      switch (action.actionType) {
        case CheckinActionType.NavigateToStep:
          if (action.nextStepId) {
            setCurrentCheckinStep(
              getData()?.checkinSteps.find((s) => s.id === action.nextStepId) ??
                ({} as CheckinStep)
            );
          }
          break;
        case CheckinActionType.ShowModal:
          setAutocloseModal(false);
          setModalConfirmActionText(
            getTranslation(action.modalConfirmActionTextTranslationKey)
          );
          setModalTitle(getTranslation(action.modalTitleTranslationKey));
          setModalText(getTranslation(action.modalTextTranslationKey));
          setIsModalOpen(true);
          break;
        case CheckinActionType.CheckIn:
          setAutocloseModal(true);
          setModalActionOnConfirm(() => handleCheckIn); // Use handleCheckIn here
          setModalConfirmActionText(
            getTranslation(action.modalConfirmActionTextTranslationKey)
          );
          setModalTitle(getTranslation(action.modalTitleTranslationKey));
          setModalText(getTranslation(action.modalTextTranslationKey));
          setIsModalOpen(true);
          break;
        case CheckinActionType.TriggerEvent:
          console.log(`Triggering event for action key: ${actionKey}`);
          break;
        default:
          console.warn("Unknown action type:", action.actionType);
          break;
      }
    });
  };

  const renderStep = () => {
    const currentStep = getData().checkinSteps?.find(
      (step) => step.id === currentCheckinStep.id
    );

    if (!currentStep) return null;

    switch (currentStep.stepType) {
      case CheckinStepType.EntrySelectClientGroupHorizontalImages:
        return (
          <SelectClientGroupHorizontalWide
            selectClientGroup={onClientGroupSelected}
            setVisitorType={onVisitorTypeSelected}
            handleAction={onHandleAction}
          />
        );
      case CheckinStepType.SelectClientPortraitCards:
        return (
          <SelectClientPortraitCards
            key={resetKey} // Use resetKey to force re-render
            selectedClientGroup={clientGroupSelected}
            resetCheckin={onReset}
            setClient={onClientSelected}
            handleAction={onHandleAction}
            setVisitorType={onVisitorTypeSelected}
          />
        );
      default:
        return <div>Step not recognized.</div>;
    }
  };

  return (
    <>
      {isModalOpen && (
        <Modal
          title={modalTitle}
          messageText={modalText}
          onClose={onReset}
          autoClose={autoCloseModal}
          confirmActionMessageText={modalConfirmActionText}
          onConfirm={modalActionOnConfirm}
        />
      )}
      {currentCheckinStep.order === 0 && <LanguagePicker />}
      {renderStep()}
    </>
  );
};

export default Checkin;
