import React, { useRef, useEffect, useState } from "react";
import {
  getDateRange,
  isBookingOnDate,
  useCurrentTime,
} from "../../helpers/DateHelper";
import { getStartAndEndTimeWithRespectOfSpans } from "../../helpers/Utils";
import { useDataContext } from "../../DataContext";

interface ColumnsAndRowsProps {}

const BookingDaysColumns: React.FC<ColumnsAndRowsProps> = () => {
  const { getData } = useDataContext();
  const [shouldAddEmptyRow, setShouldAddEmptyRow] = useState(false);
  const columnsContainerRef = useRef<HTMLDivElement | null>(null);

  const dateRange = getDateRange();
  const evenOddStyle = (index: number) =>
    index % 2 === 0
      ? JSON.parse(getData().infoStyle.evensCss ?? "{}")
      : JSON.parse(getData().infoStyle.oddsCss ?? "{}");

  // Get today's date in YYYY-MM-DD format
  const today = new Date().toISOString().split("T")[0];

  useEffect(() => {
    const checkOverflow = () => {
      const container = columnsContainerRef.current;
      if (container && window.innerHeight > container.clientHeight) {
        setShouldAddEmptyRow(true);
      } else {
        setShouldAddEmptyRow(false);
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
  }, []);

  return (
    <div
      className="app"
      style={{
        backgroundImage: getData().infoStyle?.backgroundImageBase64
          ? `url(${getData().infoStyle?.backgroundImageBase64})`
          : "{}",
      }}
    >
      {
        <div
          className="clock"
          style={JSON.parse(getData().infoStyle?.clockCss ?? "{}")}
        >
          {useCurrentTime().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          })}
        </div>
      }
      <div
        className="columns-container"
        ref={columnsContainerRef}
        style={JSON.parse(getData().infoStyle.backgroundCss ?? "{}")}
      >
        <div className="columns-header">
          <div
            className="header-item"
            style={JSON.parse(getData().infoStyle.layoutNameCss ?? "{}")}
          >
            <div>{getData().layoutName}</div>
          </div>
          {dateRange.map((date, index) => {
            const dayOfWeekCapitalized =
              new Date(date)
                .toLocaleDateString("da-DK", { weekday: "long" })
                .charAt(0)
                .toUpperCase() +
              new Date(date)
                .toLocaleDateString("da-DK", { weekday: "long" })
                .slice(1);
            const dateFormatted =
              new Date(date).getDate() + "/" + (new Date(date).getMonth() + 1);

            // Check if the date is today
            const isToday = date === today;
            const headerStyle = {
              ...JSON.parse(getData().infoStyle.dayHeaderCss ?? "{}"),
              ...(isToday
                ? JSON.parse(getData().infoStyle.todayCss ?? "{}")
                : {}),
            };

            return (
              <div key={date} className="header-item" style={headerStyle}>
                <div
                  className="column-header-day-of-week"
                  style={JSON.parse(
                    getData().infoStyle.dayHeaderDayOfWeekCss ?? "{}"
                  )}
                >
                  {dayOfWeekCapitalized}
                </div>
                <div
                  className="date-formatted"
                  style={JSON.parse(
                    getData().infoStyle.dayHeaderDateCss ?? "{}"
                  )}
                >
                  {dateFormatted}
                </div>
              </div>
            );
          })}
        </div>
        <div className="columns-body">
          {getData().calendars.map((calendar) => (
            <div key={calendar.id} className="columns-row">
              <div
                className="calendar-title events-column"
                style={JSON.parse(getData().infoStyle.calendarTitleCss ?? "{}")}
              >
                {calendar.name}
              </div>
              {dateRange.map((date) => {
                const bookingsForDate = getData().bookings.filter(
                  (booking) =>
                    booking.calendarId === calendar.id &&
                    isBookingOnDate(
                      new Date(booking.start),
                      new Date(booking.end),
                      date
                    )
                );

                const isToday = date === today;
                const columnStyle = {
                  ...evenOddStyle(dateRange.indexOf(date)),
                  ...(isToday
                    ? JSON.parse(getData().infoStyle.todayCss ?? "{}")
                    : {}),
                };

                return (
                  <div key={date} className="events-column" style={columnStyle}>
                    {bookingsForDate.length > 0 ? (
                      bookingsForDate.map((booking) => {
                        const { startTime, endTime } =
                          getStartAndEndTimeWithRespectOfSpans(booking, date);

                        return (
                          <div
                            key={booking.id}
                            className="booking-event"
                            style={JSON.parse(
                              getData().infoStyle.bookingContainerCss ?? "{}"
                            )}
                          >
                            {/* Use getFieldValue instead */}
                            {getData().showPersonOnBookings && (
                              <div
                                className="booking-person"
                                style={JSON.parse(
                                  getData().infoStyle.bookingPersonCss ?? "{}"
                                )}
                              >
                                {booking.person}
                              </div>
                            )}
                            <div
                              className="booking-title"
                              style={JSON.parse(
                                getData().infoStyle.bookingTitleCss ?? "{}"
                              )}
                            >
                              {booking.title}
                            </div>
                            <div
                              className="booking-time"
                              style={JSON.parse(
                                getData().infoStyle.bookingTimeCss ?? "{}"
                              )}
                            >
                              {startTime} - {endTime}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div
                        className="booking-event"
                        style={JSON.parse(
                          getData().infoStyle.bookingContainerCss ?? "{}"
                        )}
                      >
                        <div className="booking-time"></div>
                        <div className="booking-title"></div>
                        {getData().showPersonOnBookings && (
                          <div className="booking-person"></div>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          ))}

          {shouldAddEmptyRow && (
            <div className="columns-row empty-row">
              <div
                className="calendar-title events-column"
                style={JSON.parse(getData().infoStyle.calendarTitleCss ?? "{}")}
              ></div>
              {dateRange.map((date, index) => {
                const isToday =
                  new Date(date).toDateString() === new Date().toDateString();
                return (
                  <div
                    key={index}
                    className="events-column"
                    style={{
                      ...evenOddStyle(index),
                      ...(isToday
                        ? JSON.parse(getData().infoStyle.todayCss ?? "{}")
                        : {}),
                    }}
                  ></div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BookingDaysColumns;
