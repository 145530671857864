import React, { useState } from "react";
import "flag-icons/css/flag-icons.min.css";
import { Language } from "../../../models/ModelTypes";
import "./LanguagePicker.css";
import { useDataContext } from "../../../DataContext";

interface LanguagePickerProps {
  showAsDropdown?: boolean;
}

const LanguagePicker: React.FC<LanguagePickerProps> = ({
  showAsDropdown = false,
}) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  // const currentLanguage = dataStore.getLanguage();
  const { getLanguage, setLanguage, getData } = useDataContext();
  const localizations = getData().localizations;

  const currentLocalization = localizations?.find(
    (localization) => localization.language === getLanguage()
  );

  const toggleDropdown = () => setDropdownOpen(!isDropdownOpen);
  const closeDropdown = () => setDropdownOpen(false);

  const handleLanguageSelect = (language: Language) => {
    setLanguage(language);
    closeDropdown();
  };

  return (
    <div className="language-picker" onBlur={closeDropdown} tabIndex={0}>
      {!showAsDropdown && (
        <div className="language-list">
          {localizations?.map((localization) => {
            const countryCode =
              localization.isO_3166_1_alpha_2_code.toLowerCase();
            return (
              <div
                key={localization.isO_3166_1_alpha_2_code}
                className={`language-item ${
                  currentLocalization?.isO_3166_1_alpha_2_code.toLowerCase() ===
                  countryCode
                    ? "active"
                    : ""
                }`}
                onClick={() =>
                  handleLanguageSelect(localization.language as Language)
                }
              >
                <span className={`fi fi-${countryCode}`}></span>
              </div>
            );
          })}
        </div>
      )}
      {showAsDropdown && (
        <>
          <div
            className={`language-item ${isDropdownOpen ? "active" : ""}`}
            onClick={toggleDropdown}
          >
            <span
              className={`fi fi-${currentLocalization?.isO_3166_1_alpha_2_code.toLowerCase()}`}
            ></span>
          </div>
          {isDropdownOpen && (
            <div className="dropdown">
              {localizations?.map((localization) => {
                const countryCode =
                  localization.isO_3166_1_alpha_2_code.toLowerCase();
                return currentLocalization?.isO_3166_1_alpha_2_code.toLocaleLowerCase() !==
                  countryCode ? (
                  <div
                    key={localization.isO_3166_1_alpha_2_code}
                    className="language-item"
                    onClick={() =>
                      handleLanguageSelect(localization.language as Language)
                    }
                  >
                    <span className={`fi fi-${countryCode}`}></span>
                  </div>
                ) : null;
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default LanguagePicker;
