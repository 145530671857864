import React, { useState, useEffect, useCallback } from "react";
import "./Modal.css";
import { getTranslation } from "../../../helpers/Utils";

interface ModalProps {
  title?: string;
  messageText: string;
  confirmActionMessageText: string;
  onClose: (onlyResetModal: boolean) => void;
  autoClose?: boolean;
  onConfirm: () => void;
}

const Modal: React.FC<ModalProps> = ({
  title,
  messageText,
  confirmActionMessageText,
  onClose,
  autoClose = false,
  onConfirm,
}) => {
  const [countdown, setCountdown] = useState(10); // Countdown starting from 10 seconds
  const [isClosing, setIsClosing] = useState(false); // Tracks if modal is in closing state
  const [isConfirming, setIsConfirming] = useState(!!confirmActionMessageText); // Tracks if modal is in confirmation state

  const handleClose = useCallback(
    (onlyResetModal: boolean) => {
      setIsClosing(true); // Trigger the closing animation
      setTimeout(() => {
        onClose(onlyResetModal);
      }, 300); // Wait for the animation to complete before calling onClose
    },
    [onClose]
  );

  useEffect(() => {
    if (!autoClose || countdown === 0 || isConfirming) return;

    const timer = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 1) {
          handleClose(false); // Close modal when countdown reaches 0
          clearInterval(timer); // Clear the interval once modal is closed
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);

    return () => clearInterval(timer); // Clean up interval on unmount or countdown change
  }, [countdown, autoClose, handleClose, isConfirming]);

  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      handleClose(false);
    }
  };

  const handleYes = async () => {
    setIsConfirming(false); // Move to the main message
    await onConfirm();
  };

  const handleNo = () => {
    handleClose(true); // Close the modal
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className={`modal ${isClosing ? "closing" : ""}`}>
        {title && <div className="modal-title">{title}</div>}
        {isConfirming ? (
          <div>
            <div className="modal-text">{confirmActionMessageText}</div>
            <div className="modal-buttons">
              <button className="modal-button" onClick={handleYes}>
                {getTranslation("button_yes")}
              </button>
              <button className="modal-button" onClick={handleNo}>
                {getTranslation("button_no")}
              </button>
            </div>
          </div>
        ) : (
          <div>
            <div className="modal-text">{messageText}</div>
            <button
              className="modal-button"
              onClick={() => {
                handleClose(false);
              }}
            >
              {getTranslation("button_ok")} {autoClose ? `(${countdown})` : ""}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
