import React from "react";
import "./SelectClientGroupHorizontalWide.css";
import { getStepStyle, getTranslation } from "../../../helpers/Utils";
import { ClientGroup } from "../../../models/ClientLayoutModel";
import { useDataContext } from "../../../DataContext";
import { VisitorType } from "../../../models/ModelTypes";

interface Props {
  selectClientGroup: (clientGroup: ClientGroup) => void;
  setVisitorType: (type: VisitorType) => void;
  handleAction: (
    actionKey: string,
    visitorType: VisitorType | undefined
  ) => void;
}

const SelectClientGroupHorizontalWide: React.FC<Props> = ({
  selectClientGroup,
  setVisitorType,
  handleAction,
}) => {
  const { getData } = useDataContext();
  const clientGroups = getData()?.clientGroups;

  return (
    <div className="select-client-group-horizontal-wide-container">
      <div className="select-client-group-horizontal-wide-title-container">
        <div style={getStepStyle("title_prefix_css")}>
          {getTranslation("title_prefix")}
        </div>
        <div style={getStepStyle("title_css")}>{getTranslation("title")}</div>
        <div style={getStepStyle("title_postfix_css")}>
          {getTranslation("title_postfix")}
        </div>
      </div>
      <div className="welcome-text">{getTranslation("welcome_text")}</div>
      <div className="button-grid">
        {clientGroups.map((clientGroup) => {
          return (
            <div
              key={clientGroup.id}
              className="image-button"
              onClick={() => {
                selectClientGroup(clientGroup);
                handleAction("client_group_selected", undefined);
              }}
            >
              <img
                src={
                  clientGroup.heroImageBase64 ? clientGroup.heroImageBase64 : ""
                }
                alt={clientGroup.name}
              />
            </div>
          );
        })}
      </div>
      <div className="other-buttons-container">
        <div
          className="other-button"
          onClick={() => {
            setVisitorType(VisitorType.Courier);
            handleAction("courier_selected", VisitorType.Courier);
          }}
        >
          {getTranslation("button_courier")}
        </div>
        <div
          className="other-button"
          onClick={() => {
            setVisitorType(VisitorType.VisitorNoAppointment);
            handleAction(
              "no_appointment_selected",
              VisitorType.VisitorNoAppointment
            );
          }}
        >
          {getTranslation("button_no_appointment")}
        </div>
      </div>
    </div>
  );
};

export default SelectClientGroupHorizontalWide;
